import { Injectable } from '@angular/core';
import { CompanySettingsService } from '@app/shared/services/company-settings.service';
import { DEFAULT_DECIMALS_NUMBER } from '@app/settings/constants/settings.constants';
import { ICurrency } from '@app/shared/types/interfaces';

export const SHARE_PRICE_DECIMALS = 6;

@Injectable({
  providedIn: 'root',
})
export class MasksService {
  constructor(private companySettingsService: CompanySettingsService) {}

  public cashMask(decimals?: number): string {
    const { currency } = this.companySettingsService.settings;
    return this.mask(this.getDecimals(decimals, currency));
  }

  public ngCashFormat(decimals?: number): string {
    const { currency } = this.companySettingsService.settings;
    return this.format(this.getDecimals(decimals, currency));
  }

  private mask(decimals: number): string {
    return `separator.${decimals}`;
  }

  // TODO move format function to separate service in order to be able to use it in other places
  private format(decimals: number): string {
    return `1.${decimals}-${decimals}`;
  }

  private getDecimals(decimals: number, currency: ICurrency, defaultDecimals = DEFAULT_DECIMALS_NUMBER): number {
    return decimals ?? currency?.decimals ?? defaultDecimals;
  }
}
